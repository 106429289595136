<template>
  <v-data-table
    class="elevation-1"
    :items="data"
    :headers="computedHeaders"
    :loading="tableLoading"
  >
    <template v-slot:item.actions="{ item }">
      <v-icon small class="mr-2" @click="edit(item)"> mdi-pencil </v-icon>
      <v-icon small @click="remove(item)"> mdi-delete </v-icon>
    </template>
    <template v-slot:item.concluido="{ item }">
      {{ item.is_concluido ? "Sim" : "Não" }}
    </template>
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>PDTIC Anterior</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>

        <v-dialog v-model="dialog" max-width="600px" @click:outside="close">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on"
              >Novo</v-btn
            >
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">PDTIC Anterior</span>
            </v-card-title>
            <ValidationObserver ref="form" v-slot="{ passes }">
              <v-form @submit.prevent="passes(save)" width="100%">
                <v-card-text>
                  <v-container>
                    <ValidationProvider
                      v-if="$state.role != 'eqpdtiuo'"
                      vid="uo"
                      name="Unidade Organizacional"
                      v-slot="{ errors }"
                      rules="required"
                    >
                      <UOAutoComplete
                        v-model="Item.uo"
                        :error-messages="errors"
                      />
                    </ValidationProvider>
                    <ValidationProvider
                      vid="id_necessidade"
                      name="ID"
                      v-slot="{ errors }"
                      rules="required"
                    >
                      <v-text-field
                        v-model="Item.id_necessidade"
                        label="ID"
                        :error-messages="errors"
                      ></v-text-field>
                    </ValidationProvider>
                    <ValidationProvider
                      vid="necessidade"
                      name="Necessidade"
                      v-slot="{ errors }"
                      rules="required"
                    >
                      <v-text-field
                        v-model="Item.necessidade"
                        label="Necessidade"
                        :error-messages="errors"
                      ></v-text-field>
                    </ValidationProvider>
                    <ValidationProvider
                      vid="is_concluido"
                      name="Concluído?"
                      v-slot="{ errors }"
                      rules="required"
                    >
                      <v-checkbox
                        v-model="Item.is_concluido"
                        label="Concluído?"
                        :error-messages="errors"
                      ></v-checkbox>
                    </ValidationProvider>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="darken-1" text @click="close">Cancelar</v-btn>
                  <v-btn :loading="saving" color="darken-1" text @click="save"
                    >Salvar</v-btn
                  >
                </v-card-actions>
              </v-form>
            </ValidationObserver>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
  </v-data-table>
</template>

<script>
import { PDTICAnterior } from "@/api/eqpdti";
import UOAutoComplete from "@/components/UOAutoComplete";
export default {
  name: "PDTICAnterior",
  components: {
    UOAutoComplete,
  },
  data: () => ({
    data: [],
    headers: [
      {
        text: "ID",
        value: "id_necessidade",
        align: "center",
      },
      {
        text: "Necessidade",
        value: "necessidade",
        align: "left",
      },
      {
        text: "Foi concluído?",
        value: "concluido",
        sortable: false,
        align: "center",
      },
      {
        text: "Ações",
        value: "actions",
        sortable: false,
        align: "end",
      },
    ],
    Item: {
      necessidade: "",
      is_concluido: false,
    },
    dialog: false,
    saving: false,
    tableLoading: false,
    errors: [],
  }),
  computed: {
    computedHeaders() {
      this.fetch();
      if (this.$state.role != "eqpdtiuo") {
        return [
          {
            text: "Unidade Organizacional",
            value: "uo_sigla",
            align: "center",
          },
        ].concat(this.headers);
      }
      return this.headers;
    },
  },
  methods: {
    close() {
      this.dialog = false;
      this.$refs.form.reset();
      this.$nextTick(() => {
        this.Item = {
          necessidade: "",
          is_concluido: false,
        };
      });
    },
    save() {
      this.$refs.form.validate().then((valid) => {
        if (valid) {
          this.saving = true;
          const _then = () => {
            this.saving = false;
            this.close();
            this.fetch();
          };
          const _catch = (error) => {
            this.saving = false;
            this.errors = error.response.data;
            if (this.errors["non_field_errors"]) {
              this.$emitter.emit(
                "alert:error",
                this.errors["non_field_errors"][0]
              );
            } else {
              this.$emitter.emit("alert:error", this.errors);
            }
          };
          if (this.Item["id"]) {
            let id = this.Item["id"];

            PDTICAnterior.update(id, this.Item).then(_then).catch(_catch);
          } else {
            PDTICAnterior.post(this.Item).then(_then).catch(_catch);
          }
        }
      });
    },
    edit(item) {
      this.Item = Object.assign({}, item);
      this.dialog = true;
    },

    remove(item) {
      if (window.confirm("Deseja realmente excluir?")) {
        this.tableLoading = true;
        PDTICAnterior.remove(item["id"]).then(() => {
          this.tableLoading = false;
          this.fetch();
        });
      }
    },
    fetch() {
      this.tableLoading = true;
      PDTICAnterior.list().then((response) => {
        this.data = response.data;
        this.tableLoading = false;
      });
    },
  },
  mounted() {
    this.fetch();
  },
};
</script>
